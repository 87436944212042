import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Container from '@material-ui/core/Container'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Menu from '@material-ui/core/Menu'
import { Drawer, Fade, Link, useMediaQuery, useTheme } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import NextLink from 'next/link'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import React, { FC, useRef } from 'react'
import { useNewHeaderStyles } from './new-header.styles'
// import { useChangeLocale, useLocale } from '@plandi/common'
import { ReactComponent as FlagRu } from '../../../../assets/flagRu.svg'
import { ReactComponent as FlagEn } from '../../../../assets/flagEn.svg'
import { ReactComponent as Telegram } from '../../../../assets/new-socials/Telegram.svg'
import { ReactComponent as Youtube } from '../../../../assets/new-socials/YouTube.svg'
import { ReactComponent as XIcon } from '../../../../assets/new-socials/X.svg'
import { ReactComponent as LinkedIn } from '../../../../assets/new-socials/Linkedin.svg'
import { ReactComponent as Facebook } from '../../../../assets/new-socials/Facebook.svg'
import { ReactComponent as Vk } from '../../../../assets/new-socials/Vk.svg'
import {
  useStaticTranslate,
  SERVICES_URL,
  useQueryUser,
  useMutationLogout
} from '@plandi/common'
import { useRouter } from 'next/router'

type stateSpatcher = (prevState: boolean) => boolean
interface NewHeaderMobileDrawerProps {
  isDrawerOpen: boolean
  setIsDrawerOpen: (state: boolean | stateSpatcher) => void
}

export const NewHeaderMobileDrawer: FC<NewHeaderMobileDrawerProps> = ({
  isDrawerOpen,
  setIsDrawerOpen
}) => {
  const classes = useNewHeaderStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(960))
  const langButtonRef = useRef<HTMLButtonElement>(null)
  const [langDropOpen, setlangDropOpen] = React.useState(false)
  const [marketCollapseOpen, setMarketCollapseOpen] = React.useState(false)
  const [jobCollapseOpen, setJobCollapseOpen] = React.useState(false)
  const [docCollapseOpen, setDocCollapseOpen] = React.useState(false)
  const t = useStaticTranslate()
  const { locale } = useRouter()
  const router = useRouter()
  const url = SERVICES_URL
  const { data: user } = useQueryUser()
  const redirectOfficeUrl = user
    ? locale === 'ru'
      ? `${url.office}/ru` + '/projects'
      : url.office + '/projects'
    : locale === 'ru'
    ? `${url.office}/ru`
    : url.office
  // const locale = useLocale()
  // const { setLocale } = useChangeLocale()
  const mutationLogout = useMutationLogout({
    onSuccess: () => {
      router.push('/')
    }
  })

  return (
    <Drawer
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      classes={{ paper: classes.drawer }}
    >
      <Toolbar className={classes.mobileToolbar}>
        {isSmallScreen && (
          <IconButton
            className={classes.menuIcon}
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setIsDrawerOpen((prev) => !prev)}
          >
            <CloseIcon className={classes.menuIcon} color={'inherit'} />
          </IconButton>
        )}
        <Container className={classes.container} maxWidth={false}>
          <div className={clsx(classes.wrapper, classes.mobileWrapper)}>
            <nav className={classes.nav}>
              <>
                <Button
                  ref={langButtonRef}
                  onClick={() => {
                    setlangDropOpen((prev) => !prev)
                  }}
                >
                  {router.locale === 'ru' ? <FlagRu /> : <FlagEn />}
                  <KeyboardArrowDownIcon />
                </Button>
                <Menu
                  elevation={0}
                  id="lang-menu"
                  anchorEl={langButtonRef.current}
                  keepMounted
                  open={langDropOpen}
                  onClose={() => setlangDropOpen(false)}
                  TransitionComponent={Fade}
                  classes={{ paper: classes.menuRoot }}
                >
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                      // setLocale('ru')
                      if (user) {
                        mutationLogout.mutate()
                      }
                      router.push(router.asPath, undefined, {
                        locale: 'ru'
                      })
                      setlangDropOpen(false)
                    }}
                  >
                    <FlagRu /> Русский
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                      // setLocale('en')
                      if (user) {
                        mutationLogout.mutate()
                      }
                      router.push(router.asPath, undefined, {
                        locale: 'en'
                      })
                      setlangDropOpen(false)
                    }}
                  >
                    <FlagEn /> English
                  </MenuItem>
                </Menu>
              </>
            </nav>
          </div>
        </Container>
      </Toolbar>
      <div className={classes.mobileDrawerContentWrapper}>
        <Container className={classes.mobileLinkList}>
          {/*<NextLink href={'/'} passHref>*/}
          {/*  <Link*/}
          {/*    className={classes.link}*/}
          {/*    color={'textPrimary'}*/}
          {/*    underline={'none'}*/}
          {/*  >*/}
          {/*    О компании*/}
          {/*  </Link>*/}
          {/*</NextLink>*/}
          <div>
            <Typography
              className={classes.link}
              onClick={() => setMarketCollapseOpen((prev) => !prev)}
              component="div"
            >
              {t('market')}
              {marketCollapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Typography>
            <Collapse in={marketCollapseOpen}>
              <div className={classes.mobileLinkSubList}>
                {/* <NextLink href={'/shop/projects'}> */}
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.shop}/ru/projects`
                      : `${url.shop}/projects`
                  }
                  className={classes.link}
                  color={'textPrimary'}
                  underline={'none'}
                >
                  {t('projects')}
                </Link>
                {/* </NextLink> */}
                {/* <NextLink href={'/shop/models'}> */}
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.shop}/ru/models`
                      : `${url.shop}/models`
                  }
                  className={classes.link}
                  color={'textPrimary'}
                  underline={'none'}
                >
                  {t('bim')}
                </Link>
                {/* </NextLink> */}
                {/* <NextLink href={'/shop/goods'}> */}
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.shop}/ru/goods`
                      : `${url.shop}/goods`
                  }
                  className={classes.link}
                  color={'textPrimary'}
                  underline={'none'}
                >
                  {t('products')}
                </Link>
                {/* </NextLink> */}
              </div>
            </Collapse>
          </div>

          <div>
            <Typography
              className={classes.link}
              onClick={() => setJobCollapseOpen((prev) => !prev)}
              component="div"
            >
              {t('job')}
              {jobCollapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Typography>
            <Collapse in={jobCollapseOpen}>
              <div className={classes.mobileLinkSubList}>
                {/* <NextLink href={'/job/applicants'}> */}
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.job}/ru/applicants`
                      : `${url.job}/applicants`
                  }
                  className={classes.link}
                  color={'textPrimary'}
                  underline={'none'}
                >
                  {t('tenders')}
                </Link>
                {/* </NextLink> */}
                {/* <NextLink href={'/job/employers'}> */}
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.job}/ru/employers`
                      : `${url.job}/employers`
                  }
                  className={classes.link}
                  color={'textPrimary'}
                  underline={'none'}
                >
                  {t('specialists')}
                </Link>
                {/* </NextLink> */}
                {/* <NextLink href={'/job/companies'}> */}
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.job}/ru/companies`
                      : `${url.job}/companies`
                  }
                  className={classes.link}
                  color={'textPrimary'}
                  underline={'none'}
                >
                  {t('companies')}
                </Link>
                {/* </NextLink> */}
                {/* <NextLink href={'/job/factories'}> */}
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.job}/ru/factories`
                      : `${url.job}/factories`
                  }
                  className={classes.link}
                  color={'textPrimary'}
                  underline={'none'}
                >
                  {t('factories')}
                </Link>
                {/* </NextLink> */}
              </div>
            </Collapse>
          </div>

          {/* <Link
            className={classes.link}
            color={'textPrimary'}
            underline={'none'}
            href={`https://${
              process.env.NEXT_PUBLIC_NODE_ENV === 'stage'
                ? 'stage-office'
                : 'office'
            }.plandi.${locale === 'ru' ? 'ru' : 'io'}`}
          >
            {t('office')}
          </Link> */}
          <Link
            className={classes.link}
            color={'textPrimary'}
            underline={'none'}
            href={redirectOfficeUrl}
            //   href={`https://${
            //     process.env.NEXT_PUBLIC_NODE_ENV === 'stage'
            //       ? 'stage-office'
            //       : 'office'
            //   }.plandi.${locale === 'ru' ? 'ru' : 'io'}`}
          >
            {t('office')}
          </Link>
          {/* <NextLink href={'/blog/articles'} passHref> */}
          <Link
            href={
              locale === 'ru'
                ? `${url.blog}/ru/articles`
                : `${url.blog}/articles`
            }
            className={classes.link}
            color={'textPrimary'}
            underline={'none'}
          >
            {t('blog')}
          </Link>
          {/* </NextLink> */}
          {/* <NextLink href={'/shop/subscriptions'} passHref> */}
          <Link
            href={
              locale === 'ru'
                ? `${url.shop}/ru/subscriptions`
                : `${url.shop}/subscriptions`
            }
            className={classes.link}
            color={'textPrimary'}
            underline={'none'}
          >
            {t('subscriptions')}
          </Link>
          {/* </NextLink> */}

          <div>
            <Typography
              className={classes.link}
              onClick={() => setDocCollapseOpen((prev) => !prev)}
              component="div"
            >
              {t('documents')}
              {docCollapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Typography>
            <Collapse in={docCollapseOpen}>
              <div className={classes.mobileLinkSubList}>
                <NextLink
                  href={
                    locale === 'ru'
                      ? 'https://drive.google.com/file/d/15g13203koIU4ydyRvLZv35E5DJ_tm7gR/view?usp=drive_open'
                      : 'https://drive.google.com/file/d/1EHSNBLNAWopy05fnWPrwMeP8LYmIisiE/view'
                  }
                  passHref
                >
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    {t('user-agreement')}
                  </Link>
                </NextLink>
                <NextLink
                  href={
                    locale === 'ru'
                      ? 'https://drive.google.com/file/d/1NnXZ7WZxFx0LjXW4ibsPKr1WIRZcHvYm/view'
                      : 'https://drive.google.com/file/d/1xlj149pID4FIpJliTjZY8p2Hhyw6ZoTg/view?usp=drive_open'
                  }
                  passHref
                >
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    {t('privacy-policy')}
                  </Link>
                </NextLink>
                <NextLink
                  href={
                    locale === 'ru'
                      ? 'https://drive.google.com/file/d/16e4crPsdLnUkvzRngqm31XBjJo1rDXpL/view'
                      : 'https://drive.google.com/file/d/1ceOnfS6dQ9oRgZpEje8wRAaIZaxFJ1x8/view'
                  }
                  passHref
                >
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    {t('processing-policy')}
                  </Link>
                </NextLink>
                <NextLink
                  href={
                    locale === 'ru'
                      ? 'https://drive.google.com/file/d/1cvte_muv4VlEr7uRXp7AmpN9PHuLLZ-1/view'
                      : 'https://drive.google.com/file/d/1Dywfz5OhPc0MMQaaRL7xROo5gt1Rvnvm/view'
                  }
                  passHref
                >
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    {t('user-site-agreement')}
                  </Link>
                </NextLink>
              </div>
            </Collapse>
          </div>
        </Container>
        <div className={classes.drawerFooter}>
          <Container>
            <div className={classes.socials}>
              <Link
                target={'_blank'}
                href="https://t.me/joinchat/AAAAAEuYq1OcwjEVhZjN6A"
              >
                <Telegram />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.youtube.com/channel/UCAIG4a-FPIxW_jU0d3-AM_w?view_as=subscriber"
              >
                <Youtube />
              </Link>
              <Link target={'_blank'} href="https://twitter.com/plan_di">
                <XIcon />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.linkedin.com/in/plan-di"
              >
                <LinkedIn />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.facebook.com/plandi.ru.official?mibextid=LQQJ4d"
              >
                <Facebook />
              </Link>
              <Link target={'_blank'} href="https://vk.com/plandiruofficial">
                <Vk />
              </Link>
            </div>
            <Typography className={classes.copy}>
              &copy; Все права защищены &quot;Plandi.
              {locale === 'ru' ? 'io/ru' : 'io'}&quot; 2025
            </Typography>
          </Container>
        </div>
      </div>
    </Drawer>
  )
}
