import React, { FC } from 'react'
import Container from '@material-ui/core/Container'
import { Grid, Link } from '@material-ui/core'
import { ReactComponent as LogoFooter } from '../../../../assets/White.svg'
import { ReactComponent as Telegram } from '../../../../assets/new-socials/Telegram.svg'
import { ReactComponent as Youtube } from '../../../../assets/new-socials/YouTube.svg'
import { ReactComponent as XIcon } from '../../../../assets/new-socials/X.svg'
import { ReactComponent as LinkedIn } from '../../../../assets/new-socials/Linkedin.svg'
import { ReactComponent as Facebook } from '../../../../assets/new-socials/Facebook.svg'
import { ReactComponent as Vk } from '../../../../assets/new-socials/Vk.svg'
import { useNewFooterStyles } from './new-footer.styles'
import Typography from '@material-ui/core/Typography'
// import NextLink from 'next/link'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { SERVICES_URL, useQueryUser } from '@plandi/common'

export const NewFooter: FC = () => {
  const classes = useNewFooterStyles()
  const { locale } = useRouter()
  const url = SERVICES_URL
  const { data: user } = useQueryUser()
  const redirectOfficeUrl = user
    ? locale === 'ru'
      ? `${url.office}/ru` + '/projects'
      : url.office + '/projects'
    : locale === 'ru'
    ? `${url.office}/ru`
    : url.office

  return (
    <Container maxWidth={false} className={classes.root}>
      <Container className={classes.wrapper}>
        <Grid container spacing={6}>
          <Grid item xs md className={classes.logoContainer}>
            <LogoFooter />
            <div className={classes.socials}>
              <Link
                target={'_blank'}
                href="https://t.me/joinchat/AAAAAEuYq1OcwjEVhZjN6A"
                aria-label={'telegram'}
              >
                <Telegram />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.youtube.com/channel/UCAIG4a-FPIxW_jU0d3-AM_w?view_as=subscriber"
                aria-label={'youtube'}
              >
                <Youtube />
              </Link>
              <Link
                target={'_blank'}
                href="https://twitter.com/plan_di"
                aria-label={'x'}
              >
                <XIcon />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.linkedin.com/in/plan-di"
                aria-label={'linkedin'}
              >
                <LinkedIn />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.facebook.com/plandi.ru.official?mibextid=LQQJ4d"
                aria-label={'facebook'}
              >
                <Facebook />
              </Link>
              <Link
                target={'_blank'}
                href="https://vk.com/plandiruofficial"
                aria-label={'vkontakte'}
              >
                <Vk />
              </Link>
            </div>
            <Typography className={classes.copy}>
              &copy;{' '}
              {locale === 'ru' ? 'Все права защищены' : 'All rights reserved '}{' '}
              &quot;Plandi.{locale === 'ru' ? 'io/ru' : 'io'}&quot; 2025
            </Typography>
          </Grid>
          <Grid
            item
            container
            md={6}
            // spacing={2}
            className={classes.linksContainer}
          >
            <Grid item xs className={classes.linksColumn}>
              {/*<NextLink href={'/'} passHref>*/}
              <Typography className={clsx(classes.link, classes.headLink)}>
                {locale === 'ru' ? 'О компании' : 'About us'}
              </Typography>
              {/*</NextLink>*/}

              <Link
                href={
                  locale === 'ru'
                    ? `${url.shop}/ru${'/subscriptions'}`
                    : `${url.shop}${'/subscriptions'}`
                }
                className={classes.link}
              >
                {locale === 'ru' ? 'Подписки' : 'Subscriptions'}
              </Link>

              <Link
                href={
                  locale === 'ru'
                    ? `${url.shop}/ru${'#reviews-slider'}`
                    : `${url.shop}#reviews-slider`
                }
                className={classes.link}
              >
                {locale === 'ru' ? 'Отзывы' : 'Reviews'}
              </Link>

              <Link
                href={
                  locale === 'ru'
                    ? `${url.main}/ru${'/questions?question=job'}`
                    : `${url.main}/questions?question=job`
                }
                className={classes.link}
              >
                FAQ
              </Link>

              <Link
                href={locale === 'ru' ? `${url.main}/ru` : url.main}
                className={classes.link}
              >
                {locale === 'ru' ? 'Контакты' : 'Contact Us'}
              </Link>
            </Grid>
            <Grid item xs className={classes.linksColumn}>
              <Link
                href={locale === 'ru' ? `${url.shop}/ru` : url.shop}
                className={clsx(classes.link, classes.headLink)}
              >
                {locale === 'ru' ? 'Маркет' : 'Market'}
              </Link>

              <Link
                href={
                  locale === 'ru'
                    ? `${url.shop}/ru/projects`
                    : `${url.shop}/projects`
                }
                className={classes.link}
              >
                {locale === 'ru' ? 'Проекты' : 'Projects'}
              </Link>

              <Link
                href={
                  locale === 'ru'
                    ? `${url.shop}/ru/models`
                    : `${url.shop}/models`
                }
                className={classes.link}
              >
                {locale === 'ru' ? 'BIM-модели' : 'BIM models'}
              </Link>

              <Link
                href={
                  locale === 'ru' ? `${url.shop}/ru/goods` : `${url.shop}/goods`
                }
                className={classes.link}
              >
                {locale === 'ru' ? 'Товары' : 'Products'}
              </Link>
            </Grid>
            <Grid item xs className={classes.linksColumn}>
              <Link
                href={locale === 'ru' ? `${url.job}/ru` : url.job}
                className={clsx(classes.link, classes.headLink)}
              >
                {locale === 'ru' ? 'Работа' : 'Job'}
              </Link>

              <Link
                href={
                  locale === 'ru'
                    ? `${url.job}/ru/applicants`
                    : `${url.job}/applicants`
                }
                className={classes.link}
              >
                {locale === 'ru' ? 'Заказы' : 'Projects'}
              </Link>

              <Link
                href={
                  locale === 'ru'
                    ? `${url.job}/ru/employers`
                    : `${url.job}/employers`
                }
                className={classes.link}
              >
                {locale === 'ru' ? 'Специалисты' : 'Specialists'}
              </Link>

              <Link
                href={
                  locale === 'ru'
                    ? `${url.job}/ru/companies`
                    : `${url.job}/companies`
                }
                className={classes.link}
              >
                {locale === 'ru' ? 'Компании' : 'Companies'}
              </Link>

              <Link
                href={
                  locale === 'ru'
                    ? `${url.job}/ru/factories`
                    : `${url.job}/factories`
                }
                className={classes.link}
              >
                {locale === 'ru' ? 'Заводы' : 'Factories'}
              </Link>
            </Grid>
            <Grid item xs className={classes.linksColumn}>
              <Link
                className={clsx(classes.link, classes.headLink)}
                href={redirectOfficeUrl}
                //   href={`https://${
                //     process.env.NEXT_PUBLIC_NODE_ENV === 'stage'
                //       ? 'stage-office'
                //       : 'office'
                //   }.plandi.${locale === 'ru' ? 'ru' : 'io'}`}
              >
                {locale === 'ru' ? 'Офис' : 'Office'}
              </Link>

              <Link
                href={
                  locale === 'ru'
                    ? `${url.blog}/ru/articles`
                    : `${url.blog}/articles`
                }
                className={clsx(classes.link, classes.headLink)}
              >
                {locale === 'ru' ? 'Блог' : 'Blog'}
              </Link>
            </Grid>
          </Grid>
          <Grid item xs className={clsx(classes.linksColumn, classes.docLinks)}>
            <Link
              href={
                locale === 'ru'
                  ? `${url.main}/ru/documents-and-agreements`
                  : `${url.main}/documents-and-agreements`
              }
              className={clsx(classes.link, classes.headLink)}
            >
              {locale === 'ru'
                ? 'Документы и соглашения'
                : 'Documents and agreements'}
            </Link>

            <Link
              className={classes.link}
              href={
                locale === 'ru'
                  ? 'https://drive.google.com/file/d/15g13203koIU4ydyRvLZv35E5DJ_tm7gR/view?usp=drive_open'
                  : 'https://drive.google.com/file/d/1EHSNBLNAWopy05fnWPrwMeP8LYmIisiE/view'
              }
            >
              {locale === 'ru'
                ? 'Пользовательское соглашение'
                : 'Community Guidelines'}
            </Link>

            <Link
              className={classes.link}
              href={
                locale === 'ru'
                  ? 'https://drive.google.com/file/d/1NnXZ7WZxFx0LjXW4ibsPKr1WIRZcHvYm/view'
                  : 'https://drive.google.com/file/d/1xlj149pID4FIpJliTjZY8p2Hhyw6ZoTg/view?usp=drive_open'
              }
            >
              {locale === 'ru'
                ? 'Политика конфиденциальности'
                : 'Privacy policy'}
            </Link>

            <Link
              className={classes.link}
              href={
                locale === 'ru'
                  ? 'https://drive.google.com/file/d/16e4crPsdLnUkvzRngqm31XBjJo1rDXpL/view'
                  : 'https://drive.google.com/file/d/1ceOnfS6dQ9oRgZpEje8wRAaIZaxFJ1x8/view'
              }
            >
              {locale === 'ru'
                ? 'Соглашение об обработке персональных данных'
                : 'Data processing agreement'}
            </Link>

            <Link
              className={classes.link}
              href={
                locale === 'ru'
                  ? 'https://drive.google.com/file/d/1cvte_muv4VlEr7uRXp7AmpN9PHuLLZ-1/view'
                  : 'https://drive.google.com/file/d/1Dywfz5OhPc0MMQaaRL7xROo5gt1Rvnvm/view'
              }
            >
              {locale === 'ru'
                ? 'Правила поведения пользователей'
                : 'Terms of services'}
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}
